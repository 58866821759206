import { useRef } from 'react'

export const useConstant = <T,>(value: T): T => {
  const ref = useRef<{ value: T } | undefined>(undefined)

  if (!ref.current) {
    ref.current = { value }
  }
  return ref.current.value
}
